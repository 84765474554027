.swiper {
      /* width: 65%;
      height: 400px; */
      background-color: transparent;
}
.swiper-slide {
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      
}

.autoplay-progress {
      position: absolute;
      right: 16px;
      bottom: 16px;
      z-index: 10;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: white;
}

.autoplay-progress svg {
      --progress: 0;
      position: absolute;
      left: 0;
      top: 0px;
      z-index: 10;
      width: 100%;
      height: 100%;
      stroke-width: 4px;
      stroke: white;
      fill: none;
      stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
      stroke-dasharray: 125.6;
      transform: rotate(-90deg);
}