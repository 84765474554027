@tailwind base;
@tailwind components;
@tailwind utilities;
body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.font-family-poppins{
    font-family: "Poppins";
}
